import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { connect } from 'react-redux'

import AuthService from "../../services/auth.service";
import { fetchLoginForm } from "../../redux/thunks/user.thunks";

const required = value => {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                Обязательно для заполнения
            </div>
        );
    }
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.state = {
            email: "",
            password: "",
            loading: false,
            message: ""
        };
    }

    onChangeEmail(e) {
        this.setState({
            email: e.target.value
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    handleLogin(e) {
        e.preventDefault();

        this.setState({
            message: "",
            loading: true
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            fetchLoginForm({username: this.state.email, password: this.state.password}).then(
                (e) => {
                    if (e.roles.length) {
                        this.props.history.push("/forms");
                        window.location.reload();
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.setState({
                        loading: false,
                        message: resMessage
                    });
                }
            );
        } else {
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <div className="col-md-12">
                <img
                    className="card-container__img"
                    src="https://year18.spid.center/img/illustration-footer.jpg"
                    alt="profile-img"
                />

                <div className="card card-container">
                    {/*<img*/}
                    {/*  src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"*/}
                    {/*  alt="profile-img"*/}
                    {/*  className="profile-img-card"*/}
                    {/*/>*/}
                    <h5 className="card-container__title">Авторизация в CRM</h5>

                    <Form
                        onSubmit={ this.handleLogin }
                        ref={ c => {
                            this.form = c;
                        } }
                    >

                        <div className="form-group">
                            <label htmlFor="email">Сотрудник</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={ this.state.email }
                                onChange={ this.onChangeEmail }
                                validations={ [ required ] }
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">Пароль</label>
                            <Input
                                type="password"
                                className="form-control"
                                name="password"
                                value={ this.state.password }
                                onChange={ this.onChangePassword }
                                validations={ [ required ] }
                            />
                        </div>

                        <div className="form-group">
                            <button
                                className="btn btn-primary btn-block"
                                disabled={ this.state.loading }
                            >
                                { this.state.loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                ) }
                                <span>Войти</span>
                            </button>
                        </div>

                        { this.state.message && (
                            <div className="form-group">
                                <div className="alert alert-danger" role="alert">
                                    { this.state.message }
                                </div>
                            </div>
                        ) }
                        <CheckButton
                            style={ {display: "none"} }
                            ref={ c => {
                                this.checkBtn = c;
                            } }
                        />
                    </Form>
                </div>
            </div>
        );
    }
}

export default Login