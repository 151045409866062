export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const FETCH_ROLE = "FETCH_ROLE";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const TESTING_SET_DRUG_USED = "TESTING_SET_DRUG_USED"
export const TESTING_SET_SEX_WORKED = "TESTING_SET_SEX_WORKED"
export const TESTING_SET_PREP_USED = "TESTING_SET_PREP_USED"
export const TESTING_SET_RANGE_PERIOD = "TESTING_SET_RANGE_PERIOD"
export const TESTING_CITY = "TESTING_CITY"
export const TESTING_FIND_BY_CODE = "TESTING_FIND_BY_CODE";

export const DIAGRAM_FAIL = "DIAGRAM_FAIL";
export const DIAGRAM_SUCCESS = "DIAGRAM_SUCCESS";
