import React, { useEffect, useState, useCallback } from "react";
import { PageHeader, Divider, Tabs, Row, Col, Button } from "antd";
import { useNotification } from "../hooks";
import {
  getPatients,
  postPatients,
  deletePatient,
} from "../redux/thunks/patients.thunks";
import { PatientForm } from "./PatientForm";
import { PatientsTable } from "./PatientsTable";
import { useHistory } from "react-router";
import { PatientsFilter } from "./PatientsFilter";
import { getVisitXslx } from "../redux/thunks/visits.thunks";

export const PatientsPage = () => {
  const [patients, setPatients] = useState([]);
  const { openNotification } = useNotification();
  const history = useHistory();
  const [filter, setFilter] = useState({});

  const handleChange = useCallback(
    (changes) => {
      setFilter({
        ...filter,
        ...changes,
      });
    },
    [filter]
  );

  const reload = useCallback((filter) => {
    getPatients(filter).then((res) => setPatients(res.data));
  }, []);

  useEffect(() => {
    reload(filter);
  }, [reload, filter]);

  const createProfile = (profile) => {
    postPatients(profile)
      .then((res) => {
        const { id } = res.data;
        openNotification("Пациент успешно добавлен");
        reload();
        history.push(`/visits/patients/${id}`);
      })
      .catch(() => {
        openNotification("При добавлении произошла ошибка");
      });
  };

  const handleDelete = async (ids) => {
    await Promise.all(
      ids.map((id) => {
        return deletePatient(id);
      })
    );
    openNotification(`Пациенты удалены`);
    reload();
  };

  const downloadXslx = useCallback(async (num) => {
    try {
      const blob = await getVisitXslx(num);
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `visit-${num}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <PageHeader title="Пациенты" />

      <PatientsFilter onChange={handleChange} />
      <Divider />

      <Row>
        <Col span={15}>
          <Button onClick={() => downloadXslx(1)} type="link">
            Визит 1 (xslx)
          </Button>
          <Button onClick={() => downloadXslx(2)} type="link">
            Визит 2 (xslx)
          </Button>
          <Button onClick={() => downloadXslx(3)} type="link">
            Визит 3 (xslx)
          </Button>
          <Button onClick={() => downloadXslx(4)} type="link">
            Визит 4 (xslx)
          </Button>
          <Button onClick={() => downloadXslx(5)} type="link">
            Визит 5 (xslx)
          </Button>
          <Button onClick={() => downloadXslx(6)} type="link">
            Визит 6 (xslx)
          </Button>
        </Col>
      </Row>
      <Divider />

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Просмотр" key="1">
          <PatientsTable patients={patients} onDelete={handleDelete} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Добавить" key="2">
          <PatientForm onUpdate={createProfile} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};
